import { configureStore } from "@reduxjs/toolkit";
import { appLogger } from "../middleware/logger";
import { wordsReducer } from "../slices/wordsSlice";
import { counterReducer } from "../slices/counterSlice";

export const store = configureStore({
  reducer: {
    appWords: wordsReducer,
    counter: counterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appLogger),
});
