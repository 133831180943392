import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/types";
interface WordsState {
  words: string[];
  chosenWords: string[];
}

const initialState: WordsState = {
  words: [],
  chosenWords: [],
};

type WordsAction = PayloadAction<string[]>;

export const wordsSlice = createSlice({
  name: "words",
  initialState,
  reducers: {
    setWords: (state, action: WordsAction) => {
      state.words = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clearWords: (state, _: PayloadAction<void>) => {
      state.words = [];
    },
    setChosenWords: (state, action: WordsAction) => {
      state.chosenWords = action.payload;
    },
  },
});

export const { setWords, clearWords, setChosenWords } = wordsSlice.actions;
export const selectWords = (state: RootState): string[] => state.appWords.words;
export const selectChosenWords = (state: RootState): string[] =>
  state.appWords.chosenWords;
export const wordsReducer = wordsSlice.reducer;
