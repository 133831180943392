import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/types";

interface CounterState {
  value: number;
}

const initialState: CounterState = {
  value: -1,
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    setValue: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});

export const { increment, setValue } = counterSlice.actions;
export const selectCounter = (state: RootState): number => state.counter.value;
export const counterReducer = counterSlice.reducer;
