import { useEffect, useState } from "react";

type TKey = { key: string };
type SetStateFn = (value: boolean) => void;
type THandler = (targetKeys: string[], pressedValue: boolean, setKeyPressed: SetStateFn) => ({ key }: TKey) => void;

const handler: THandler = (targetKeys: string[], pressedValue: boolean, setKeyPressed: SetStateFn) => ({ key }: TKey) => {
  if (targetKeys.includes(key)) {
    setKeyPressed(pressedValue);
  } else {
    console.log("Key pressed", key);
  }
};

export const useKeyPress = (targetKeys: string[]): boolean => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState<boolean>(false);
  // If pressed key is our target key then set to true
  const downHandler = handler(targetKeys, true, setKeyPressed);
  const upHandler = handler(targetKeys, false, setKeyPressed);

  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
    // Empty array ensures that effect is only run on mount and unmount
  }, []);

  return keyPressed;
};
