/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable indent */
import { appLog } from "../logger/appLog";

export const appLogger =
  (appStore: { getState: () => any }) =>
  (next: (arg0: any) => any) =>
  (action: { type: any }) => {
    appLog.info(`Dispatching: ${action.type}`);
    appLog.info("Next State...");
    appLog.info(appStore.getState());
    return next(action);
  };
