import { createTheme } from "@material-ui/core";

const impact = {
  fontFamily: "Impact",
  fontStyle: "normal",
  fontDisplay: "auto",
  fontWeight: 400,
  src: `
  local('./fonts/impact.ttf') format('truetype')`,
};

export const theme = createTheme({
  palette: {
    background: {
      default: "#000",
    },
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#000",
    },
  },
  typography: {
    fontFamily: "Impact, Roboto",
    subtitle1: {
      color: "white",
      wordBreak: "break-word",
      wordWrap: "break-word",
    },
    subtitle2: {
      fontSize: "350%",
      color: "white",
      wordBreak: "break-word",
      wordWrap: "break-word",
    },
    h1: {
      fontSize: "1000%",
      color: "white",
      wordBreak: "break-word",
      wordWrap: "break-word",
    },
    h2: {
      fontSize: "600%",
      color: "white",
      marginTop: "4rem",
      wordBreak: "break-word",
      wordWrap: "break-word",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        "@font-face": [impact as any],
      },
    },
  },
});
