import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useCallback } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { setChosenWords, selectWords } from "../../slices/wordsSlice";
import { shuffle } from "underscore";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: 275,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#ffffff",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export const WordDisplayer: React.FC = () => {
  const words = useAppSelector(selectWords);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  const calculateWordsAndGo = useCallback(() => {
    const calculatedWords = shuffle(words).slice(0, 20);
    dispatch(setChosenWords(calculatedWords));
    history.push("/rapgod");
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "4rem" }}
      >
        <Typography variant="subtitle2">{words.join(" • ")}</Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: "4rem" }}
      >
        <Grid container item xs={4}>
          <Button
            fullWidth
            onClick={() => calculateWordsAndGo()}
            className={"button-pink button-outlined-white"}
          >
            <h2>Randomise word order</h2>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
