/* eslint-disable react/no-unescaped-entities */

import {
  TextField,
  Button,
  makeStyles,
  Theme,
  createStyles,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { setWords } from "../../slices/wordsSlice";
import { RootState } from "../../store/types";
import { mapInputToWordArray } from "../../utils/words";

const useStylesReddit = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "1px solid #e2e2e1",
      overflow: "hidden",
      borderRadius: 4,
      backgroundColor: "#000",
      color: "#fff",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: "#000",
        color: "#fff",
      },
      "&$focused": {
        backgroundColor: "#000",
        color: "#fff",
        borderColor: "#fff",
      },
    },
    focused: {},
  })
);

const WordInputFetcherInternal: React.FC = () => {
  const words = useAppSelector((state: RootState) => state.appWords.words);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStylesReddit();

  const onTextChange = (input: string): void => {
    if (input === null || input === undefined) {
      return;
    }
    const wordsAsCorrect = mapInputToWordArray(input);
    if (wordsAsCorrect.length > 0) {
      dispatch(setWords(wordsAsCorrect));
    }
  };

  return (
    <div className={"word-input-fetcher-container"}>
      <div>
        <Typography variant="subtitle1">
          Input rap words separated with a new line. This will strip any extra
          spaces or words that end in a single ','
        </Typography>
      </div>
      <TextField
        multiline
        fullWidth
        variant="filled"
        label="Paste words below"
        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
          onTextChange(e.target.value)
        }
        color="primary"
        InputProps={{
          classes,
          disableUnderline: true,
        }}
      />
      <Button
        disabled
        fullWidth
        className={"button-pink"}
        style={{ marginTop: "2rem" }}
      >
        Your chosen words:
      </Button>
      <div style={{ marginTop: "2rem" }}>
        {words.map((x) => (
          <div key={v4()}>
            <Typography variant="subtitle1">{x}</Typography>
          </div>
        ))}
      </div>
      <Button
        fullWidth
        className={"button-pink button-outlined-white"}
        style={{ marginTop: "4rem" }}
        onClick={() => history.push("/words")}
      >
        Click to see word cloud
      </Button>
    </div>
  );
};

export const WordInputFetcher = WordInputFetcherInternal;
