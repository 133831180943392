import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import Countdown, { CountdownRendererFn } from "react-countdown";
import { v4 } from "uuid";
import { useAppSelector } from "../../hooks/hooks";
import { useKeyPress } from "../../hooks/useKeyPress";
import { selectChosenWords } from "../../slices/wordsSlice";
import { MultiWordDisplay } from "./MultiWordDisplay";

const useStyles = makeStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "black",
  },
}));

const time = 180000;

const keyListenerIds = ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"];

const lastFiveWordsIndexValues = [15, 16, 17, 18, 19];

// music start playing on load

const audio = new Audio("/20WordsBeat.mp3");
audio.loop = false;

export const RapGod: React.FC = () => {
  const words = useAppSelector(selectChosenWords);
  const classes = useStyles();
  const keyPressed = useKeyPress(keyListenerIds);

  const [localCounter, setLocalCounter] = useState(-1);
  const [date, setDate] = useState<number | undefined>(undefined);

  useEffect(() => {
    audio.play();
  }, [audio]);

  const videoComponent = (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <video autoPlay={true}>
        <source src="./RAPGODEND.mp4" type="video/mp4" />
      </video>
    </Grid>
  );

  const countdownRenderer: CountdownRendererFn = (p) => (
    <Typography variant="h1">
      {p.formatted.minutes} : {p.formatted.seconds}
    </Typography>
  );

  useEffect(() => {
    if (keyPressed === true) {
      if (localCounter === -1) {
        setDate(Date.now() + time);
        return setLocalCounter(0);
      }

      return setLocalCounter((prevState) => prevState + 1);
    }
  }, [keyPressed, audio]);

  const initialWordsGrid = (
    <Grid item xs={12} style={{ marginTop: "4rem" }}>
      <Grid
        container
        item
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          {localCounter > -1 && (

            <Card className={classes.root}>
              <CardContent>
                <Typography variant="h1" style={{ marginTop: "4rem" }}>
                  {`${localCounter + 1}. ${words[localCounter]}`}
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const lastFiveWords = lastFiveWordsIndexValues.map(v => {
    const displayWord = words[v];

    const display = (
      <MultiWordDisplay classNames={classes.root} localCounter={v} key={v4()} word={displayWord} />
    );

    return localCounter <= v 
      ? display 
      : null;
  })
    .filter(x => x !== null);

  const lastWordsGrid = (
    <Grid item xs={12} style={{ marginTop: "4rem" }}>
      <Grid
        container
        item
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {lastFiveWords}
      </Grid>
    </Grid>
  );

  const grid = (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      spacing={0}
      direction="column"
      style={{ marginTop: "4rem" }}
    >
      <Grid xs={4} item>
        {date && (
          <Countdown
            date={date}
            onComplete={() => console.log("countdown completed")}
            renderer={countdownRenderer}
          />
        )}
      </Grid>
      {localCounter <= 14 && initialWordsGrid}
      {localCounter > 14 && localCounter <= 19 && lastWordsGrid}
    </Grid>
  );

  return (
    <div className={classes.root}>
      {localCounter <= 19 && grid}
      {localCounter > 19 && videoComponent}
    </div>
  );
};
