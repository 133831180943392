import { Container, CssBaseline, ThemeProvider } from "@material-ui/core";
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import { Provider } from "react-redux";
import { store } from "./store/store";
import { WordInputFetcher } from "./components/WordInputFetcher/WordInputFetcher";
import { WordDisplayer } from "./components/WordDisplayer/WordDisplayer";
import { RapGod } from "./components/Rapgod/Rapgod";
import { theme } from "./styles/theme";

export const App = (): JSX.Element => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Container maxWidth="lg">
              <BrowserRouter>
                <Route exact path="/" component={WordInputFetcher} />
                <Route path="/words" component={WordDisplayer} />
                <Route path="/rapgod" component={RapGod} />
              </BrowserRouter>
            </Container>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};
