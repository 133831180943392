import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";

type Props = { 
  classNames: string;
  localCounter: number;
  word: string;
}

export const MultiWordDisplay: React.FC<Props> = ({ classNames, localCounter, word }: Props) => (
  <Card className={classNames}>
    <CardContent>
      <Typography variant="subtitle2">
        {`${localCounter + 1}. ${word}`}
      </Typography>
    </CardContent>
  </Card>
);